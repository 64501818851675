import styled from 'styled-components';
import { breakpoint, colors, fontWeight, trans, rgba, fontFamily } from 'src/styledVars';
import { Link } from 'react-router-dom';

export const Wrap = styled.div`
  margin: 0 auto;
  max-width: 1850px;
  padding: 0 25px;
`;

export const Logo = styled.div<{ logoimage?: string; isLanding: boolean }>`
  position: absolute;
  height: ${props => (props.isLanding ? `70px;` : '40px;')};
  width: ${props => (props.isLanding ? `70px;` : '40px;')};
  top: 20px;
  left: 25px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${props => (props.logoimage ? `url(${props.logoimage})` : 'none')};
  background-size: contain;

  ${breakpoint.lg} {
    display: none;
  }
`;

export const LogoMenu = styled.div<{ logoimage?: string }>`
  position: absolute;
  height: 40px;
  width: 40px;
  top: 20px;
  left: 50%;
  margin-left: -25px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${props => (props.logoimage ? `url(${props.logoimage})` : 'none')};
  background-size: contain;
`;

export const MainHeader = styled.header`
  left: 0;
  padding: 20px 0;
  position: absolute;
  top: 0;
  transition: filter ${trans.long};
  width: 100%;
  z-index: 5;

  ${breakpoint.md} {
    ${Wrap} {
      padding: 0 0 0 15px;
    }
  }
`;

export const HeaderHolder = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 21px;

  ${breakpoint.md} {
    justify-content: flex-start;
    margin-bottom: 16px;
  }
`;

export const TouchNav = styled.button<{ isLanding: boolean }>`
  background: none;
  border: 0;
  cursor: pointer;
  display: none;
  height: 38px;
  margin-right: 2px;
  position: relative;
  width: 38px;

  &::before,
  &::after,
  span {
    background-color: ${colors.black};
    border-radius: 1px;
    content: '';
    height: 2px;
    left: 0;
    opacity: 1;
    position: absolute;
    transition: ${trans.normal};
    width: 24px;
  }

  &::before {
    top: 10px;
  }

  span {
    top: 50%;
    transform: translateY(-50%);
  }

  &::after {
    bottom: 10px;
  }

  ${breakpoint.lg} {
    display: ${props => (props.isLanding ? 'none' : 'block')};
  }
`;

export const HeaderHome = styled(Link)`
  background-image: linear-gradient(to right, ${colors.darkOrange}, ${colors.sand});
  color: ${colors.white};
  display: inline-block;
  font-size: 28px;
  font-weight: ${fontWeight.demi};
  letter-spacing: 0.59px;
  line-height: 16px;
  padding: 12px 18px 11px 5px;
  text-decoration: none;
  margin-left: 50px;

  ${breakpoint.lg} {
    font-size: 24px;
  }

  ${breakpoint.md} {
    font-size: calc(6px + 2.53vw);
  }
`;

export const HeaderHomeHolder = styled.div<{ isLanding: boolean }>`
  display: 'flex';
  justify-content: space-between;

  ${HeaderHome} {
    display: ${props => (props.isLanding ? 'none' : 'inline-block')};
  }

  ${breakpoint.md} {
    justify-content: flex-start;
  }
`;

export const HeaderDownload = styled.div`
  align-items: center;
  display: flex;

  ${breakpoint.lg} {
    margin-left: auto;
  }
`;

export const HeaderMobNav = styled.div<{ isMenuOpen: boolean; isLanding: boolean }>`
  display: flex;

  ${breakpoint.lg} {
    background-color: ${colors.white};
    display: block;
    left: 0;
    padding: 50px 20px 35px 50px;
    position: fixed;
    top: 0;
    transform: ${props => (props.isMenuOpen ? 'translateX(0)' : 'translateX(-100%)')};
    transition: ${props => (props.isLanding ? 'none' : trans.normal)};
    width: 50%;
    z-index: 12;
  }

  ${breakpoint.md} {
    width: 100%;
  }
`;

export const HeaderMobNavClose = styled.button`
  background: none;
  border: 0;
  cursor: pointer;
  height: 38px;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 38px;
  display: none;

  &::before,
  &::after {
    background-color: ${colors.black};
    border-radius: 1px;
    content: '';
    height: 2px;
    left: 50%;
    position: absolute;
    transition: ${trans.normal};
    width: 24px;
  }

  &::before {
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    bottom: 50%;
    transform: translate(-50%, 50%) rotate(45deg);
  }

  ${breakpoint.lg} {
    display: block;
  }
`;

export const HeaderMobLang = styled.div`
  display: none;
  padding-top: 40px;

  ${breakpoint.lg} {
    display: block;
  }
`;

export const HeaderLangTitle = styled.h2`
  color: ${colors.black};
  font-size: 18px;
  font-weight: ${fontWeight.normal};
  margin-bottom: 18px;
  letter-spacing: 3px;
  text-transform: uppercase;
  max-width: 200px;
`;

export const HeaderLang = styled.div`
  border-bottom: 1px solid ${rgba(colors.black, 0.3)};
  margin-bottom: 18px;
  padding-bottom: 18px;
`;

export const HeaderLangItem = styled.button<{ isActive?: boolean }>`
  background: none;
  border: 0;
  border-bottom: ${props => (props.isActive ? `1px solid ${colors.black}` : 'none')};
  cursor: pointer;
  padding: 0;
  position: relative;
  text-align: center;
  width: 24px;
  font-family: ${fontFamily.default};
  font-weight: ${fontWeight.normal};
  font-size: 15px;
  letter-spacing: 3.4px;
  &:not(:last-child) {
    margin-right: 36px;
  }
`;

export const HeaderDownloadTextLink = styled.button`
  color: ${colors.black};
  font-size: 11px;
  font-weight: ${fontWeight.normal};
  letter-spacing: 3.74px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  font-family: ${fontFamily.default};

  &::after {
    background-color: ${colors.black};
    bottom: -2px;
    content: '';
    height: 1px;
    left: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
  }

  ${breakpoint.lg} {
    font-size: 18px;
    letter-spacing: 0;

    &::after {
      bottom: 3px;
      height: 2px;
    }
  }
`;

export const HeaderDownloadText = styled.p<{ isLanding: boolean }>`
  color: ${colors.black};
  font-size: 11px;
  font-weight: ${fontWeight.normal};
  letter-spacing: 3.74px;
  text-transform: uppercase;
  display: ${props => (props.isLanding ? 'none' : 'block')};

  ${breakpoint.lg} {
    font-size: 18px;
    font-weight: ${fontWeight.normal};
    letter-spacing: 3px;
    line-height: 20px;
    max-width: 260px;
  }
`;

export const HeaderDownloadCounter = styled.div<{ isLanding: boolean }>`
  background-image: linear-gradient(to right, ${colors.sand}, ${colors.darkOrange});
  display: ${props => (props.isLanding ? 'none' : 'flex')};
  margin-left: 32px;
  padding: 12px 16px 11px;

  ${breakpoint.lg} {
    margin-left: 7px;
  }
`;

export const HeaderDownloadCurrent = styled.span`
  color: ${colors.white};
  font-size: 28px;
  font-weight: ${fontWeight.demi};
  line-height: 16px;

  ${breakpoint.lg} {
    font-size: 24px;
  }

  ${breakpoint.md} {
    font-size: calc(6px + 2.53vw);
  }
`;

export const HeaderDownloadSumm = styled.span`
  color: ${colors.white};
  font-size: 28px;
  font-weight: ${fontWeight.light};
  line-height: 16px;

  &::before {
    content: '/';
  }

  ${breakpoint.lg} {
    font-size: 24px;
  }

  ${breakpoint.md} {
    font-size: calc(6px + 2.53vw);
  }
`;

export const HeaderBack = styled.button<{ isLanding: boolean }>`
  background: none;
  border: 0;
  color: ${colors.darkOrange};
  cursor: pointer;
  display: ${props => (props.isLanding ? 'none' : 'inline-block')};
  font-size: 11px;
  font-weight: ${fontWeight.demi};
  letter-spacing: 3.85px;
  padding-left: 25px;
  position: relative;
  text-transform: uppercase;

  &::before {
    background-color: ${colors.darkOrange};
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
  }

  ${breakpoint.lg} {
    padding-left: 40px;

    &::before {
      width: 24px;
    }
  }
`;
