import styled, { css } from 'styled-components';
import { breakpoint, colors, fontFamily, fontWeight, trans, duration, rgba } from 'src/styledVars';
import { BracketsHalf } from 'src/components/Brackets/styled';
import { CtaButton } from '../Intro/styled';
import { FacebookShareButton } from 'react-share';

export const ModalRating = styled.span<{ isVertical?: boolean }>`
  color: ${colors.darkBlue};
  font-size: ${props => (props.isVertical ? '32px' : '34px')};
  font-weight: ${props => (props.isVertical ? '400' : '500')};
  pointer-events: none;
  position: relative;
  user-select: none;
  line-height: 1;

  &::after,
  &::before {
    background-color: ${colors.darkGrey};
    border-radius: 50%;
    content: '';
    display: ${props => (props.isVertical ? 'block' : 'none')};
    height: 6px;
    left: 50%;
    margin-top: 14px;
    position: absolute;
    top: 100%;
    width: 6px;
  }

  &::before {
    transform: translateX(-7px);
  }

  &::after {
    transform: translateX(4px);
  }

  ${breakpoint.md} {
    font-size: ${props => (props.isVertical ? '18px' : '30px')};

    &::after,
    &::before {
      margin-top: 10px;
    }
  }
`;

export const ModalRatingNo = styled.span`
  position: relative;
`;

export const ModalSufix = styled.span<{ isVertical?: boolean }>`
  display: ${props => (props.isVertical ? 'none' : 'inline-block')};
  height: 16px;
  margin-left: 3px;
  position: relative;
  transform: translateY(-38%);
  width: 9px;

  ${breakpoint.md} {
    height: 12px;
    margin-left: 2px;
    transform: translateY(-53%);
    width: 7px;
  }
  ${breakpoint.tabletExtendedWide} {
    height: 12px;
    margin-left: 2px;
    transform: translateY(-53%);
    width: 7px;
  }
`;

export const ModalResult = styled.span<{ isVertical?: boolean }>`
  color: ${colors.darkBlue};
  font-size: ${props => (props.isVertical ? '22px' : '84px')};
  font-weight: ${fontWeight.normal};
  opacity: 0;
  padding: ${props => (props.isVertical ? '20px' : '0')};

  ${ModalSufix} {
    display: ${props => (props.isVertical ? 'none' : 'inline-block')};
    height: 38px;
    position: relative;
    transform: translateY(-48%);
    width: 23px;
  }

  ${breakpoint.md} {
    font-size: ${props => (props.isVertical ? '22px' : '58px')};

    ${ModalSufix} {
      height: 30px;
      transform: translateY(-29%);
      width: 17px;
    }
  }

  ${breakpoint.sm} {
    padding-top: 0;
    font-size: 14px;
  }

  ${breakpoint.mobile} {
    padding-top: 0;
    font-size: 14px !important;
  }
`;

export const ModalNote = styled.p<{ isVertical?: boolean }>`
  top: ${props => (props.isVertical ? '129px' : 'auto')};
  bottom: ${props => (props.isVertical ? 'auto' : '30px')};
  color: ${colors.darkBlue};
  font-size: 17px;
  font-weight: ${fontWeight.book};
  opacity: 0;
  padding: 0 20px;
  position: absolute;
  text-align: center;
  width: 100%;

  ${breakpoint.md} {
    top: 122px;
    bottom: auto;
    font-size: 15px;
    padding: 0 20px;
  }

  ${breakpoint.sm} {
    top: 90px;
    bottom: auto;
    font-size: 12px;
    padding: 0 20px;
  }

  ${breakpoint.mobile} {
    top: 90px !important;
    bottom: auto;
    font-size: 12px;
    padding: 0 20px;
  }
`;

export const ModalNoteAnswer = styled.span`
  position: relative;
  margin-left: 5px;
  ${breakpoint.md} {
    display: block;
    margin-left: 0;
    padding: 0 20px;
  }
  ${breakpoint.mobile} {
    display: block;
    margin-left: 0;
    padding: 0 20px;
    max-width: 143px;
    margin: 0 auto;
  }
`;

export const ModalNoteAnswerSufix = styled.span<{ isVertical?: boolean }>`
  display: ${props => (props.isVertical ? 'none' : 'inline')};
`;

export const ModalShare = styled(FacebookShareButton)`
  height: 39px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: -59px;
  top: 50%;
  transform: translateY(-50%);
  transition: ${trans.normal};
  width: 39px;

  .d {
    fill: none;
  }

  .a {
    fill: ${colors.white};
    stroke-width: 2px;
    stroke: ${colors.darkOrange};
    transition: ${trans.normal};
  }

  .b {
    fill: url('#a');
    transition: ${trans.normal};
  }

  .c {
    stroke: none;
  }

  ${breakpoint.hover} {
    &:hover {
      .a {
        fill: ${colors.orange};
        stroke: ${colors.orange};
      }

      .b {
        fill: ${colors.white};
      }
    }
  }

  ${breakpoint.md} {
    right: -14vw;
  }
`;

export const ModalGrade = styled.div`
  color: ${colors.darkBlue};
  display: inline-block;
  font-family: ${fontFamily.secondary};
  font-size: 22px;
  font-weight: ${fontWeight.normal};
  letter-spacing: 0.22px;
  opacity: 0;
  position: relative;
  text-indent: 0.22px;

  ${breakpoint.sm} {
    font-size: 17px;
    margin-top 5px;
  }

  ${breakpoint.mobile} {
    font-size: 17px;
    margin-top 5px;
  }

  &::after {
    background-color: ${colors.black};
    bottom: -1px;
    content: '';
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 70%;
  }
`;

export const ModalGradeHolder = styled.div`
  padding: 0 20px;
  position: absolute;
  text-align: center;
  top: 21px;
  width: 100%;

  ${breakpoint.md} {
    top: 6px;
  }
`;

export const ModalTitle = styled.h2`
  font-size: 20px;
  font-weight: ${fontWeight.demi};
  left: 0;
  letter-spacing: 6.16px;
  margin-top: -7px;
  position: absolute;
  text-indent: 6.16px;
  text-transform: uppercase;
  top: 0;
  width: 100%;

  ${breakpoint.md} {
    font-size: 16px;
    letter-spacing: 4px;
    margin-top: -40px;
    text-indent: 4px;
    top: 0;
  }
`;

export const ModalIntro = styled.p`
  font-size: 17px;
  font-weight: ${fontWeight.book};
  line-height: 20px;
  margin: 0 auto;
  max-width: 392px;
  opacity: 1;
  transition: ${trans.long};
  ${breakpoint.md} {
    font-size: 15px;
  }
  ${breakpoint.mobile} {
    font-size: 14px;
  }
  ${breakpoint.xxs} {
    font-size: 13px;
  }
`;

export const ModalIntroHolder = styled.div`
  left: 0;
  position: absolute;
  top: 44px;
  width: 100%;

  ${breakpoint.md} {
    padding: 0 12vw;
    top: 5px;
  }
`;

export const ModalOutroHolder = styled.div`
  bottom: 100px;
  left: 0;
  position: absolute;
  width: 100%;

  ${breakpoint.md} {
    bottom: 80px;
    padding: 0 12vw;
  }
  ${breakpoint.mobile} {
    bottom: 50px;
  }
`;

export const ModalOutro = styled.p`
  font-size: 17px;
  font-weight: ${fontWeight.book};
  margin: 0 auto;
  max-width: 438px;
  opacity: 0;
  transition: ${trans.long};
  user-select: none;
  ${breakpoint.md} {
    font-size: 16px;
  }
  ${breakpoint.mobile} {
    font-size: 14px;
  }
  ${breakpoint.xxs} {
    font-size: 13px;
  }
`;

export const ModalOutroSources = styled.a`
  color: ${colors.black};
  display: inline-block;
  font-size: 17px;
  font-weight: ${fontWeight.book};
  opacity: 0;
  pointer-events: 'none';
  text-decoration: underline;
  transition: color ${trans.normal};

  ${breakpoint.hover} {
    &:hover,
    &:focus {
      color: ${colors.orange};
    }
  }
  ${breakpoint.md} {
    font-size: 16px;
  }
`;
export const ModalPercent = styled.svg`
  max-width: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

export const ModalAnswer = styled.div<{ isVertical?: boolean }>`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  opacity: 1;
  overflow: hidden;
  transition: ${trans.long};

  .swiper-container {
    height: ${props => (props.isVertical ? '200px' : '100px')};
    margin-left: ${props => (props.isVertical ? 'auto' : '-122px')};
    margin-top: ${props => (props.isVertical ? 0 : '54px')};
  }

  ${breakpoint.xxs} {
    .swiper-container {
      height: 170px;
    }
  }

  .swiper-wrapper {
    height: inherit;
  }

  .swiper-slide {
    align-items: ${props => (props.isVertical ? 'flex-start' : 'center')};
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: ${props => (props.isVertical ? '100%' : '78px')};

    &.swiper-slide-active {
      width: ${props => (props.isVertical ? '100%' : '200px')};

      ${ModalRating} {
        font-size: ${props => (props.isVertical ? '32px' : '84px')};
        font-weight: ${props => (props.isVertical ? fontWeight.book : fontWeight.normal)};
        color: ${props => (props.isVertical ? colors.orange : colors.darkBlue)};
      }

      ${ModalSufix} {
        height: 38px;
        position: relative;
        transform: translateY(-48%);
        width: 23px;
      }
    }
  }

  ${breakpoint.md} {
    align-items: flex-end;
    .swiper-container {
      margin-bottom: ${props => (props.isVertical ? '80px' : '14.5vh')};
      margin-left: ${props => (props.isVertical ? 'auto' : '-99px')};
      margin-top: 0;
    }
    .swiper-slide {
      align-items: center;
      display: flex;
      justify-content: center;
      width: ${props => (props.isVertical ? '100%' : '68px')};
      padding: ${props => (props.isVertical ? '0 15px' : '0')};

      &.swiper-slide-active {
        width: ${props => (props.isVertical ? '100%' : '166px')};

        ${ModalSufix} {
          height: 30px;
          transform: translateY(-29%);
          width: 17px;
        }

        ${ModalRating} {
          font-size: ${props => (props.isVertical ? '18px' : '58px')};
        }
      }
    }
  }

  ${breakpoint.mobile} {
    .swiper-container {
      margin-bottom: ${props => (props.isVertical ? '70px' : '96px')};
      margin-left: ${props => (props.isVertical ? 'auto' : '-99px')};
      margin-top: 0;
    }
  }

  ${breakpoint.tabletExtendedWide} {
    .swiper-slide {
      width: ${props => (props.isVertical ? '100%' : '78px')};
      &.swiper-slide-active {
        width: ${props => (props.isVertical ? '100%' : '166px')};
        ${ModalSufix} {
          height: 30px;
          transform: translateY(-29%);
          width: 17px;
        }

        ${ModalRating} {
          font-size: ${props => (props.isVertical ? '18px' : '58px')};
        }
      }
    }
    .swiper-container {
      margin-left: ${props => (props.isVertical ? 'auto' : '-91px')};
    }
  }
`;

export const ModalNextButton = styled(CtaButton)`
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translateX(-50%);
  pointer-events: none;
`;

export const ModalValidateButton = styled(CtaButton)`
  left: 50%;
  opacity: 1;
  position: absolute;
  transform: translateX(-50%);
`;

export const CtaHolder = styled.div`
  bottom: 25px;
  height: 30px;
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 2;

  ${breakpoint.md} {
    bottom: 0;
  }
`;

export const ModalBrackets = styled.div<{ isVertical?: boolean }>`
  align-items: center;
  bottom: 99px;
  display: flex;
  height: 190px;
  justify-content: center;
  left: 50%;
  opacity: ${props => (props.isVertical ? 0 : 1)};
  position: absolute;
  transform: translateX(-50%);
  transition: ${trans.long};
  width: 200px;
  pointer-events: none;
  z-index: ${props => (props.isVertical ? -1 : 3)};

  ${BracketsHalf} {
    background-image: linear-gradient(to top, transparent, ${rgba(colors.sand, 0.9)}, transparent);
    background-size: 12px 80%;
    background-repeat: no-repeat;
    background-position: left center;
    & + ${BracketsHalf} {
      background-position: right center;
    }
    &::after {
      border-radius: 14%;
      border: 12px solid ${colors.orange};
      height: 100%;
      min-width: auto;
      position: absolute;
      width: 200px;
      z-index: -1;
    }
  }

  ${breakpoint.md} {
    bottom: 11vh;
    height: 158px;
    width: 166px;

    ${BracketsHalf} {
      width: 23%;
    }
  }

  ${breakpoint.mobile} {
    bottom: 70px;
    height: 158px;
    width: 166px;
    ${ModalResult} {
      font-size: ${props => (props.isVertical ? '22px' : '58px')};
    }

    ${ModalSufix} {
      height: 30px;
      transform: translateY(-29%);
      width: 17px;
    }

    ${ModalGradeHolder} {
      top: 6px;
    }

    ${ModalNote} {
      top: 122px;
      font-size: 15px;
      padding: 0 20px;
    }
  }
`;

export const ModalQuestion = styled.div<{ isValidate: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;

  ${props =>
    props.isValidate &&
    css`
      ${ModalBrackets} {
        bottom: 260px;
        opacity: 1;
        width: 240px;
        z-index: 1;
      }

      ${ModalValidateButton},
      ${ModalIntro} {
        opacity: 0;
        visibility: hidden;
        user-select: none;
      }

      ${ModalAnswer} {
        opacity: 0;
        z-index: -1;
        user-select: none;
      }

      ${ModalNote},
      ${ModalGrade},
      ${ModalShare} {
        animation: fade-in 1s ${duration.normal} forwards;
      }

      ${ModalShare} {
        pointer-events: all;
      }

      ${ModalResult},
      ${ModalOutro} {
        opacity: 1;
        user-select: all;
      }

      ${ModalNextButton},
      ${ModalOutroSources} {
        opacity: 1;
        pointer-events: all;
      }

      ${breakpoint.md} {
        ${ModalBrackets} {
          bottom: 60%;
          height: 158px;
          width: 166px;
          z-index: 10;
        }
      }
      ${breakpoint.mobile} {
        bottom: 0;
        ${ModalBrackets} {
          bottom: 178px;
        }
      }
    `};
`;
