import styled from 'styled-components';
import { breakpoint } from 'src/styledVars';

export const CustomSelect = styled.div`
  display: flex;
  font-size: 11px;
  margin-left: 30px;
  position: relative;

  select {
    background: none;
    border: none;
  }

  ${breakpoint.lg} {
    display: none;
  }
`;
