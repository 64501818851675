export const questionUrls = {
  getAllQuestionsInAllLngs: process.env.REACT_APP_SERVER_URL + 'api/questions',
  downloadRapport: process.env.REACT_APP_SERVER_URL + 'api/rapport',
};

export const categoryUrls = {
  getAllCategoriesInAllLngs: process.env.REACT_APP_SERVER_URL + 'api/categories',
};

export const userDataUrls = {
  sendUserData: process.env.REACT_APP_SERVER_URL + 'api/data',
};
