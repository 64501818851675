import { colors } from 'src/styledVars';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: block;
`;

export const Loading = styled.div`
  align-items: center;
  backdrop-filter: blur(6px);
  color: ${colors.orange};
  display: flex;
  font-size: 26px;
  height: 100%;
  justify-content: center;
  left: 0;
  letter-spacing: 2px;
  line-height: 1;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
`;

export const LoadingText = styled.p`
  position: relative;
  display: inline-flex;
`;
