/* eslint-disable react/display-name */
import React, { lazy, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Category } from 'src/stores/category/model';
import { RootStore } from 'src/stores/rootStore';

const Landing = lazy(() => import('./Landing'));
const ScreenThemes = lazy(() => import('./ScreenThemes'));
const ScreenSubtheme = lazy(() => import('./ScreenSubtheme'));

export const PATHS = {
  landingPage: '/',
  screenThemes: '/screen-themes',
};

interface RouteProps {
  path: string;
  exact: boolean;
  key: string;
  component: () => JSX.Element;
}

export const STATIC_ROUTES = [
  { path: PATHS.landingPage, key: PATHS.landingPage, exact: true, component: (): JSX.Element => <Landing /> },
  { path: PATHS.screenThemes, key: PATHS.screenThemes, exact: true, component: (): JSX.Element => <ScreenThemes /> },
];

export const SUBTHEME_ROOT_URL = '/';

export const useAppRoutes: () => RouteProps[] = (): RouteProps[] => {
  const categories = useSelector((state: RootStore) => state.category.categories);

  const subthemeRoutes = useMemo(() => {
    return categories.map(
      (category: Category): RouteProps => {
        const subThemeRoute = {
          path: SUBTHEME_ROOT_URL + category.routeExtension,
          key: SUBTHEME_ROOT_URL + category.routeExtension,
          exact: true,
          component: () => (
            <ScreenSubtheme
              subThemeId={category.id}
              subThemeName={category.title}
              subThemeDesc={category.description}
              imageUrl={category.imageSubTheme}
              visitButton={category.visitButton}
            />
          ),
        };
        return subThemeRoute;
      }
    );
  }, [categories]);

  const RoutesWithSubThemes = useMemo(() => {
    return [...STATIC_ROUTES, ...subthemeRoutes];
  }, [subthemeRoutes]);

  return RoutesWithSubThemes;
};
