import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { questionUrls } from 'src/urls';
import { UserAnswer, UserData } from 'src/stores/userAnswer/model';
import { sendAgainUnsendUserData } from 'src/stores/userAnswer/action';
import { unsendUserAnswersAndUserInfoSelector } from 'src/helper/selector';
import { questionsSelector, userAnswersSelector } from './selector';
import { CtaButton, CtaButtonLine } from 'src/features/ScreenSubtheme/Intro/styled';
import * as Styled from './styled';
import { Question } from 'src/stores/question/model';

type Inputs = {
  email: string;
};

const DownloadForm: React.FC = () => {
  const { t } = useTranslation();
  const [successMessage, setSuccessMessage] = useState(false);
  const [postCallFailed, setPostCallFailed] = useState(false);
  const { register, handleSubmit, errors } = useForm<Inputs>();
  const unsendUserData = useSelector(unsendUserAnswersAndUserInfoSelector);
  const userAnswers = useSelector(userAnswersSelector);
  const appQuestions = useSelector(questionsSelector);
  const answeredQuestions = useMemo(() => {
    return appQuestions.filter((question: Question) => {
      let isAnswered = false;
      userAnswers.forEach((userAnswer: UserAnswer) => {
        if (userAnswer.questionId === question.id) {
          isAnswered = true;
        }
      });
      return isAnswered;
    });
  }, [appQuestions, userAnswers]);

  const dispatch = useDispatch();

  const onSubmit: SubmitHandler<Inputs> = useCallback(
    async data => {
      try {
        const userData: UserData = {
          userAnswers,
          userUid: unsendUserData.userUid,
          userTimestamp: unsendUserData.userTimestamp,
        };
        await axios.post(questionUrls.downloadRapport, { userData, email: data.email, questions: answeredQuestions });
        setSuccessMessage(true);
        if (unsendUserData.userAnswers && unsendUserData.userAnswers.length > 0)
          dispatch(sendAgainUnsendUserData(unsendUserData));
      } catch (e) {
        setPostCallFailed(true);
      }
    },
    [dispatch, unsendUserData, userAnswers, answeredQuestions]
  );

  return (
    <Styled.DownloadFormContainer>
      <Styled.DownloadForm onSubmit={handleSubmit(onSubmit)} isVisible={!successMessage} noValidate>
        <Styled.DownloadFormHeadline>{t('downloadRapport.title')}</Styled.DownloadFormHeadline>
        <Styled.DownloadFormIntro>{t('downloadRapport.textIntro')}</Styled.DownloadFormIntro>
        <Styled.DownloadFormInputLabel hidden htmlFor={'email'}>
          {t('downloadRapport.emailField')}
        </Styled.DownloadFormInputLabel>
        <Styled.DownloadFormInput
          id={'email'}
          name={'email'}
          type={'email'}
          placeholder={t('downloadRapport.emailField')}
          isValid={errors.email ? true : false}
          ref={register({ required: true, pattern: /^\S+@\S+$/i })}
        />
        {errors.email && (
          <Styled.DownloadFormError>{t('downloadRapport.emailValidationMissingError')}</Styled.DownloadFormError>
        )}
        {postCallFailed && (
          <Styled.DownloadFormError>{t('downloadRapport.postCallToServerError')}</Styled.DownloadFormError>
        )}
        <Styled.DownloadFormBottonHolder>
          <Styled.DownloadFormBottonHolderIcon src='/images/icons/file-pdf.svg' />
          <CtaButton light type={'submit'}>
            <CtaButtonLine>{t('downloadRapport.button')}</CtaButtonLine>
          </CtaButton>
        </Styled.DownloadFormBottonHolder>
      </Styled.DownloadForm>
      <Styled.DownloadFormStatus isVisible={successMessage}>
        <Styled.DownloadFormStatusHeadline>{t('downloadRapport.thankYouHeadline')}</Styled.DownloadFormStatusHeadline>
        <p>{t('downloadRapport.thankYouText')}</p>
        <em>{t('downloadRapport.thankYouNote')}</em>
      </Styled.DownloadFormStatus>
    </Styled.DownloadFormContainer>
  );
};

export default DownloadForm;
