import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/translate.json';
import de from './locales/de/translate.json';
import fr from './locales/fr/translate.json';
import it from './locales/it/translate.json';

i18n
  .use(LanguageDetector)
  .use(XHR)
  .use(initReactI18next)
  .init({
    resources: {
      en,
      de,
      fr,
      it,
    },
    fallbackLng: 'en',
    load: 'languageOnly',
    supportedLngs: ['en', 'de', 'fr', 'it'],
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindI18nStore: 'added removed',
      nsMode: 'default',
      useSuspense: false,
    },
  });

export default i18n;
