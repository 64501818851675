import { UserAnswer } from './model';

export const REFRESH_FETCH_DATE_TIME = 'REFRESH_FETCH_DATE_TIME';
export const ADD_USER_ANSWER = 'ADD_USER_ANSWER';
export const ADD_USER_ANSWER_TO_UNSED_ANSWERS = 'ADD_USER_ANSWER_TO_UNSED_ANSWERS';
export const RESET_USER_ANSWERS = 'RESET_USER_ANSWERS';
export const RESET_UNSEND_USER_ANSWERS = 'RESET_UNSEND_USER_ANSWERS';

export interface RefreshFetchDateTime {
  type: typeof REFRESH_FETCH_DATE_TIME;
  payload: number;
}

export interface AddUserAnswer {
  type: typeof ADD_USER_ANSWER;
  payload: UserAnswer;
}

export interface AddUserAnswerToUnsendAnswers {
  type: typeof ADD_USER_ANSWER_TO_UNSED_ANSWERS;
  payload: UserAnswer;
}

export interface ResetUserAnswers {
  type: typeof RESET_USER_ANSWERS;
}

export interface ResetUnsendUserAnswers {
  type: typeof RESET_UNSEND_USER_ANSWERS;
}

export type UserAnswerDispatchTypes =
  | RefreshFetchDateTime
  | AddUserAnswer
  | AddUserAnswerToUnsendAnswers
  | ResetUserAnswers
  | ResetUnsendUserAnswers;
