import { Question } from './model';

export const FETCH_QUESTIONS = 'FETCH_QUESTIONS';
export const SET_QUESTIONS_IN_LANGUAGE = 'SET_QUESTIONS_IN_LANGUAGE';
export const QUESTION_FAIL = 'QUESTION_FAIL';

interface PayloadForFetch {
  questionsInAllLngs: Question[];
  questionsInStartingLanguage: Question[];
}

export interface FetchQuestions {
  type: typeof FETCH_QUESTIONS;
  payload: PayloadForFetch;
}

export interface SetQuestionsInLanguage {
  type: typeof SET_QUESTIONS_IN_LANGUAGE;
  payload: Question[];
}

export interface QuestionFail {
  type: typeof QUESTION_FAIL;
}

export type QuestionDispatchTypes = FetchQuestions | SetQuestionsInLanguage | QuestionFail;
