import { CategoryDispatchTypes, CATEGORIES_FAIL, FETCH_CATEGORIES, SET_CATEGORIES_IN_LANGUAGE } from './actionType';
import { Category } from './model';

interface DefaultState {
  loading: boolean;
  categories: Category[];
  categoriesInAllLngs: Category[];
}

const defaultState: DefaultState = {
  loading: false,
  categories: [],
  categoriesInAllLngs: [],
};

const categoryReducer = (state: DefaultState = defaultState, action: CategoryDispatchTypes): DefaultState => {
  switch (action.type) {
    case CATEGORIES_FAIL:
      return {
        ...state,
        loading: false,
      };
    case FETCH_CATEGORIES:
      return {
        categories: action.payload.categoriesInStartingLanguage,
        loading: false,
        categoriesInAllLngs: action.payload.categoriesInAllLngs,
      };
    case SET_CATEGORIES_IN_LANGUAGE:
      return {
        ...state,
        categories: action.payload,
      };
    default:
      return state;
  }
};

export default categoryReducer;
