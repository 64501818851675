export const MOCKED_VISIT_BUTTON_DEFAULTS = {
  en: {
    label: 'Visit syzgroup.com',
    link: 'https://www.syzgroup.com/',
  },
  de: {
    label: 'DE Visit syzgroup.com',
    link: 'https://www.syzgroup.com/',
  },
  fr: {
    label: 'FR Visit syzgroup.com',
    link: 'https://www.syzgroup.com/',
  },
  it: {
    label: 'IT Visit syzgroup.com',
    link: 'https://www.syzgroup.com/',
  },
};

export const MOCKED_VISIT_BUTTON_SPORT = {
  label: 'Visit sport syzgroup.com',
  link: 'https://www.syzgroup.com/sport',
};

export const MOCKED_VISIT_BUTTON_SPORT_DE = {
  ...MOCKED_VISIT_BUTTON_SPORT,
  label: 'DE ' + MOCKED_VISIT_BUTTON_SPORT.label,
};

export const MOCKED_VISIT_BUTTON_SPORT_FR = {
  ...MOCKED_VISIT_BUTTON_SPORT,
  label: 'FR ' + MOCKED_VISIT_BUTTON_SPORT.label,
};

export const MOCKED_VISIT_BUTTON_SPORT_IT = {
  ...MOCKED_VISIT_BUTTON_SPORT,
  label: 'IT ' + MOCKED_VISIT_BUTTON_SPORT.label,
};

export const MOCKED_VISIT_BUTTON_ENTREPRENEURS = {
  label: 'Visit entrepreneurs syzgroup.com',
  link: 'https://www.syzgroup.com/entrepreneurs',
};

export const MOCKED_VISIT_BUTTON_ENTREPRENEURS_DE = {
  ...MOCKED_VISIT_BUTTON_ENTREPRENEURS,
  label: 'DE ' + MOCKED_VISIT_BUTTON_ENTREPRENEURS.label,
};

export const MOCKED_VISIT_BUTTON_ENTREPRENEURS_FR = {
  ...MOCKED_VISIT_BUTTON_ENTREPRENEURS,
  label: 'FR ' + MOCKED_VISIT_BUTTON_ENTREPRENEURS.label,
};

export const MOCKED_VISIT_BUTTON_ENTREPRENEURS_IT = {
  ...MOCKED_VISIT_BUTTON_ENTREPRENEURS,
  label: 'IT ' + MOCKED_VISIT_BUTTON_ENTREPRENEURS.label,
};

export const MOCKED_VISIT_BUTTON_ECONOMY = {
  label: 'Visit economy syzgroup.com',
  link: 'https://www.syzgroup.com/economy',
};

export const MOCKED_VISIT_BUTTON_ECONOMY_DE = {
  ...MOCKED_VISIT_BUTTON_ECONOMY,
  label: 'DE ' + MOCKED_VISIT_BUTTON_ECONOMY.label,
};

export const MOCKED_VISIT_BUTTON_ECONOMY_FR = {
  ...MOCKED_VISIT_BUTTON_ECONOMY,
  label: 'FR ' + MOCKED_VISIT_BUTTON_ECONOMY.label,
};

export const MOCKED_VISIT_BUTTON_ECONOMY_IT = {
  ...MOCKED_VISIT_BUTTON_ECONOMY,
  label: 'IT ' + MOCKED_VISIT_BUTTON_ECONOMY.label,
};

export const MOCKED_VISIT_BUTTON_ART = {
  label: 'Visit art syzgroup.com',
  link: 'https://www.syzgroup.com/art',
};

export const MOCKED_VISIT_BUTTON_ART_DE = {
  ...MOCKED_VISIT_BUTTON_ART,
  label: 'DE ' + MOCKED_VISIT_BUTTON_ART.label,
};

export const MOCKED_VISIT_BUTTON_ART_FR = {
  ...MOCKED_VISIT_BUTTON_ART,
  label: 'FR ' + MOCKED_VISIT_BUTTON_ART.label,
};

export const MOCKED_VISIT_BUTTON_ART_IT = {
  ...MOCKED_VISIT_BUTTON_ART,
  label: 'IT ' + MOCKED_VISIT_BUTTON_ART.label,
};
