import styled from 'styled-components';
import { colors, fontWeight, trans } from 'src/styledVars';

export const DownloadFormContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center;
  position: relative;
  padding: 0 20px;
`;

export const DownloadForm = styled.form<{ isVisible?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  max-width: 582px;
  min-height: 440px;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  position: relative;
  transform: ${props => (props.isVisible ? 'translateY(0)' : 'translateY(-150%)')};
  transition: ${trans.normalEaseInOut};
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  width: 100%;
  z-index: ${props => (props.isVisible ? 1 : -1)};
`;

export const DownloadFormHeadline = styled.h2`
  font-size: 20px;
  font-weight: ${fontWeight.demi};
  letter-spacing: 3px;
  line-height: 1.25;
  margin: 0 auto auto;
  max-width: 350px;
  position: relative;
  text-transform: uppercase;
  width: 100%;
`;

export const DownloadFormIntro = styled.p`
  font-size: 17px;
  font-weight: ${fontWeight.book};
  line-height: 1.17;
  margin-bottom: 27px;
  position: relative;
`;

export const DownloadFormInputLabel = styled.label`
  font-size: 17px;
  text-align: left;
  width: 100%;
`;

export const DownloadFormInput = styled.input<{ isValid?: boolean }>`
  border: ${props => (props.isValid ? `1px solid ${colors.red}` : `1px solid ${colors.grey2}`)};
  color: ${colors.black};
  font-size: 18px;
  font-weight: ${fontWeight.normal};
  height: 51px;
  line-height: 1;
  padding: 14px 15px;
  text-align: left;
  transition: border-color ${trans.normalEaseInOut};
  width: 100%;

  &:not(:invalid):focus {
    border-color: ${colors.black};
  }

  &:invalid {
    background-color: ${colors.white};
  }

  &::placeholder {
    color: ${colors.grey2};
    text-transform: uppercase;
  }
`;

export const DownloadFormBottonHolder = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: auto;
  padding-bottom: 20px;
  position: relative;
`;

export const DownloadFormBottonHolderIcon = styled.img`
  flex: none;
  margin-bottom: 20px;
  object-fit: contain;
  position: relative;
  width: 33px;
`;

export const DownloadFormStatus = styled.div<{ isVisible?: boolean }>`
  color: ${colors.black};
  font-size: 17px;
  left: 50%;
  line-height: 1.2;
  max-width: 400px;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity ${trans.normalEaseInOut};
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  width: 100%;
  z-index: ${props => (props.isVisible ? 1 : -1)};
`;

export const DownloadFormStatusHeadline = styled.p`
  color: ${colors.orange};
  font-size: 22px;
  font-weight: ${fontWeight.bold};
  line-height: 1.2;
  margin-bottom: 15px;
  position: relative;
`;

export const DownloadFormError = styled.div`
  color: ${colors.red};
  display: block;
  font-weight: ${fontWeight.demi};
  line-height: 1.4;
  position: relative;
  text-align: left;
  width: 100%;
`;
