import styled, { css } from 'styled-components';
import { breakpoint, colors, trans } from 'src/styledVars';

export const BracketsHolder = styled.div`
  align-items: center;
  display: flex;
  height: var(--vh);
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
`;

export const BracketsHalf = styled.div<{ rightSide?: boolean }>`
  height: 100%;
  left: ${props => (props.rightSide ? 'auto' : 0)};
  overflow: hidden;
  position: absolute;
  right: ${props => (props.rightSide ? 0 : 'auto')};
  top: 0;
  width: 28%;

  &::after {
    border-radius: 15%;
    border: 26px solid ${colors.white};
    box-sizing: border-box;
    content: '';
    height: 100%;
    left: ${props => (props.rightSide ? 'auto' : 0)};
    min-width: 350px;
    position: absolute;
    right: ${props => (props.rightSide ? 0 : 'auto')};
    top: 0;
    width: 22vw;
  }

  ${breakpoint.tablet} {
    &::after {
      min-width: 450px;
    }
  }

  ${breakpoint.md} {
    &::after {
      border: 20px solid ${colors.white};
      min-width: auto;
      width: calc(100vw - 102px);
    }
  }

  ${breakpoint.mobile} {
    &::after {
      border: 20px solid ${colors.white};
      min-width: auto;
      width: 200px;
    }
  }
`;

export const Brackets = styled.div<{ isTheme?: boolean; stretch?: boolean }>`
  height: ${props => (props.isTheme ? '70%' : '400px')};
  min-width: ${props => (props.isTheme ? '260px' : '350px')};
  position: relative;
  transition: ${trans.long};
  width: ${props => (props.isTheme ? '595px' : '22%')};

  ${props =>
    props.isTheme &&
    css`
      ${BracketsHalf} {
        width: 22%;

        &::after {
          border-radius: 14%;
          border: 34px solid ${colors.white};
          height: 100%;
          min-width: 300px;
          position: absolute;
          width: 595px;
        }
      }

      ${breakpoint.tablet} {
        &::after {
          border-width: 28px;
          width: 520px;
        }
      }

      ${breakpoint.sm} {
        height: 311px;
        margin-bottom: -78px;
        max-width: 311px;
        width: calc(100% - 102px);

        &::after {
          border-width: 20px;
          max-width: 311px;
          width: calc(100% - 102px);
        }
      }

      ${breakpoint.mobile} {
        width: 22%;

        &::after {
          width: calc(100% - 102px);
          max-width: 311px;
          border-width: 20px;
        }
      }
    `};

  ${props =>
    props.stretch &&
    css`
      height: 70%;
      max-height: 540px;
      opacity: 0;
      width: 740px;

      ${breakpoint.tablet} {
        height: 60%;
      }

      ${breakpoint.md} {
        margin-bottom: 0;
        max-width: calc(100% - 50px);
      }
    `};

  ${breakpoint.tablet} {
    height: ${props => (props.isTheme ? '60%' : '42%')};
    margin-bottom: ${props => (props.isTheme ? '-33px' : 0)};
    min-width: 450px;
    width: ${props => (props.isTheme ? '520px' : '22%')};
  }

  ${breakpoint.md} {
    height: 44vh;
    max-height: 300px;
    min-width: auto;
    width: calc(100% - 102px);
  }

  ${breakpoint.mobile} {
    height: ${props => (props.isTheme ? '214px' : '42%')};
    margin-bottom: ${props => (props.isTheme ? '-41px' : 0)};
    max-width: ${props => (props.isTheme ? '311px' : 'auto')};
    min-width: auto;
    width: ${props => (props.isTheme ? 'calc(100% - 102px)' : '285px')};
  }
`;
