import { UserIdProperties } from './model';

export const CREATE_USER_ID_PROPERTIES = 'CREATE_USER_ID_PROPERTIES';
export const UPDATE_USER_EMAIL = 'REFRESH_FETCH_DATE_TIME';

export interface CreateUserIdProperties {
  type: typeof CREATE_USER_ID_PROPERTIES;
  payload: UserIdProperties;
}

export interface RefreshFetchDateTime {
  type: typeof UPDATE_USER_EMAIL;
  payload: string;
}

export type UserInfoDispatchTypes = CreateUserIdProperties | RefreshFetchDateTime;
