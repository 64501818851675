import {
  UserAnswerDispatchTypes,
  ADD_USER_ANSWER,
  REFRESH_FETCH_DATE_TIME,
  ADD_USER_ANSWER_TO_UNSED_ANSWERS,
  RESET_UNSEND_USER_ANSWERS,
  RESET_USER_ANSWERS,
} from './actionType';
import { UserAnswer } from './model';

interface DefaultState {
  lastFetchDateTime: number;
  userAnswers: UserAnswer[];
  unsendUserAnswers: UserAnswer[];
}

const defaultState: DefaultState = {
  lastFetchDateTime: 0,
  userAnswers: [],
  unsendUserAnswers: [],
};

const userAnswerReducer = (state: DefaultState = defaultState, action: UserAnswerDispatchTypes): DefaultState => {
  switch (action.type) {
    case REFRESH_FETCH_DATE_TIME:
      return {
        ...state,
        lastFetchDateTime: action.payload,
      };
    case ADD_USER_ANSWER:
      return {
        ...state,
        userAnswers: [...state.userAnswers, action.payload],
      };
    case ADD_USER_ANSWER_TO_UNSED_ANSWERS:
      return {
        ...state,
        unsendUserAnswers: [...state.unsendUserAnswers, action.payload],
      };
    case RESET_USER_ANSWERS:
      return {
        ...state,
        userAnswers: [],
      };
    case RESET_UNSEND_USER_ANSWERS:
      return {
        ...state,
        unsendUserAnswers: [],
      };
    default:
      return state;
  }
};

export default userAnswerReducer;
