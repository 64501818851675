import { CategoryDispatchTypes, FETCH_CATEGORIES, CATEGORIES_FAIL, SET_CATEGORIES_IN_LANGUAGE } from './actionType';
import { Dispatch } from 'redux';
import { MOCKED_CATEGORIES } from './mockData';
import { Category } from './model';
import axios from 'axios';
import { categoryUrls } from 'src/urls';
import { MOCKED_VISIT_BUTTON_DEFAULTS } from '../visitButton/mockData';
import { SET_VISIT_BUTTON_CURRENT, SET_VISIT_BUTTON_DEFAULT, VisitButtonDispatchType } from '../visitButton/actionType';

function getMockData() {
  const response = {
    data: {
      visitButtonDefaults: MOCKED_VISIT_BUTTON_DEFAULTS,
      categories: MOCKED_CATEGORIES,
    },
  };
  return response;
}

export const fetchCategories = (
  startingLanguage: string
): ((dispatch: Dispatch<CategoryDispatchTypes | VisitButtonDispatchType>) => Promise<void>) => async (
  dispatch: Dispatch<CategoryDispatchTypes | VisitButtonDispatchType>
) => {
  try {
    let res;
    if (process.env.REACT_APP_FETCH_MOCK_CATEGORIES_AND_QUESTIONS === 'true') res = await getMockData();
    else res = await axios.get(categoryUrls.getAllCategoriesInAllLngs);
    const { categories, visitButtonDefaults } = res.data;
    dispatch({
      type: FETCH_CATEGORIES,
      payload: {
        categoriesInAllLngs: categories,
        categoriesInStartingLanguage: categories.filter((category: Category) => category.language === startingLanguage),
      },
    });
    dispatch({
      type: SET_VISIT_BUTTON_DEFAULT,
      payload: visitButtonDefaults,
    });
    const currentVisitButton = visitButtonDefaults[startingLanguage] || Object.values(visitButtonDefaults)[0];
    dispatch({
      type: SET_VISIT_BUTTON_CURRENT,
      payload: currentVisitButton,
    });
  } catch (e) {
    dispatch({
      type: CATEGORIES_FAIL,
    });
  }
};

export const setCategoriesInLanguage = (
  allCategories: Category[],
  choosenLanguage: string
): ((dispatch: Dispatch<CategoryDispatchTypes>) => void) => (dispatch: Dispatch<CategoryDispatchTypes>) => {
  const updatedCategories = allCategories.filter((category: Category) => category.language === choosenLanguage);

  dispatch({
    type: SET_CATEGORIES_IN_LANGUAGE,
    payload: updatedCategories,
  });
};
