import {
  UserAnswerDispatchTypes,
  ADD_USER_ANSWER,
  REFRESH_FETCH_DATE_TIME,
  ADD_USER_ANSWER_TO_UNSED_ANSWERS,
  RESET_UNSEND_USER_ANSWERS,
  RESET_USER_ANSWERS,
} from './actionType';
import { Dispatch } from 'redux';
import { UserData } from './model';
import { userDataUrls } from '../../urls';
import axios from 'axios';

// eslint-disable-next-line
export const refreshLastFetchDateTime = () => (dispatch: Dispatch<UserAnswerDispatchTypes>) => {
  const currentDateTime = Date.now();

  dispatch({
    type: REFRESH_FETCH_DATE_TIME,
    payload: currentDateTime,
  });

  dispatch({
    type: RESET_USER_ANSWERS,
  });
};

export const addUserAnswer = (
  questionId: number,
  answerId: number
): ((dispatch: Dispatch<UserAnswerDispatchTypes>) => Promise<void>) => async (
  dispatch: Dispatch<UserAnswerDispatchTypes>
) => {
  const userAnswer = { questionId, answerId };

  dispatch({
    type: ADD_USER_ANSWER,
    payload: userAnswer,
  });
};

export const sendUserData = (
  userData: UserData
): ((dispatch: Dispatch<UserAnswerDispatchTypes>) => Promise<void>) => async (
  dispatch: Dispatch<UserAnswerDispatchTypes>
) => {
  try {
    await axios.post(userDataUrls.sendUserData, userData);
  } catch (e) {
    dispatch({
      type: ADD_USER_ANSWER_TO_UNSED_ANSWERS,
      payload: userData.userAnswers[0],
    });
  }
};

export const sendAgainUnsendUserData = (
  userData: UserData
): ((dispatch: Dispatch<UserAnswerDispatchTypes>) => Promise<void>) => async (
  dispatch: Dispatch<UserAnswerDispatchTypes>
) => {
  try {
    await axios.post(userDataUrls.sendUserData, userData);
    dispatch({
      type: RESET_UNSEND_USER_ANSWERS,
    });
    // eslint-disable-next-line no-empty
  } catch (e) {}
};
