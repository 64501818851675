import styled from 'styled-components';
import { breakpoint, colors, trans } from 'src/styledVars';
import { Brackets, BracketsHalf } from 'src/components/Brackets/styled';

export const Fullviewport = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: var(--vh);
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const Subtheme = styled.div<{ isTopicsActive?: boolean; isMobileScroll?: boolean }>`
  background-image: url('/images/bg.jpg');
  background-position: ${props => (props.isTopicsActive ? '30%' : '50%')};
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  overflow: hidden;
  position: relative;
  transition: background-position ${trans.long}, filter ${trans.long};
  ${breakpoint.mobile} {
    height: ${props => (props.isMobileScroll ? '525px' : 'auto')};
  }
`;

export const SubthemeSections = styled(Fullviewport)`
  display: block;
`;

export const SubthemeBreckets = styled(Brackets)<{ isTopicsActive?: boolean }>`
  height: 70%;
  max-height: 540px;
  min-width: 260px;
  opacity: 1;
  transition: ${trans.long};
  width: 595px;
  z-index: ${props => (props.isTopicsActive ? 2 : 0)};

  ${breakpoint.lg} {
    height: 313px;
    width: 311px;
    margin-bottom: -71px;
  }
  ${breakpoint.xsExtended} {
    height: 270px;
  }

  ${breakpoint.mobile} {
    height: 70%;
  }
`;

export const SubthemeBrecketsHalf = styled(BracketsHalf)`
  width: 22%;

  &::after {
    border-radius: 14%;
    border: 34px solid ${colors.white};
    height: 100%;
    min-width: 300px;
    position: absolute;
    width: 595px;
  }

  ${breakpoint.lg} {
    width: 82px;

    &::after {
      border-width: 20px;
      max-width: 311px;
      width: calc(100% - 102px);
    }
  }
`;
