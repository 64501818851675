import { applyMiddleware, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import RootReducer from './rootReducer';

export const middlewares = [thunk];

const persistConfig = {
  key: 'root',
  storage,
};

const presistedReducer = persistReducer(persistConfig, RootReducer);
const store = createStore(presistedReducer, composeWithDevTools(applyMiddleware(...middlewares)));
const persistor = persistStore(store);
export { persistor, store };

type RootReducerType = typeof RootReducer;
export type RootStore = ReturnType<RootReducerType>;
