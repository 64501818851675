/* eslint-disable react/display-name */
import React, { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import * as Styled from './styled';

interface RouteProps {
  path: string;
  exact: boolean;
  key: string;
  component: () => JSX.Element;
}

const RouteWithSubRoutes: React.FC<RouteProps> = ({ path, exact, component }) => {
  return <Route path={path} exact={exact} component={component} />;
};

interface RenderRoutesProps {
  routes: RouteProps[];
}

export const RenderRoutes: React.FC<RenderRoutesProps> = ({ routes }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const momizedRoutes = useMemo(() => {
    return routes.map(route => {
      return <RouteWithSubRoutes {...route} key={route.key} />;
    });
  }, [routes]);

  return (
    <Styled.Wrapper>
      <TransitionGroup className='transition-group'>
        <CSSTransition key={location.key} timeout={300} classNames='blur'>
          <Suspense
            fallback={
              <Styled.Loading>
                <Styled.LoadingText>{t('shared.loading')}</Styled.LoadingText>
              </Styled.Loading>
            }
          >
            <Switch>
              {momizedRoutes}
              <Route component={() => <h1>Not Found!</h1>} />
            </Switch>
          </Suspense>
        </CSSTransition>
      </TransitionGroup>
    </Styled.Wrapper>
  );
};
