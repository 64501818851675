import styled, { css } from 'styled-components';
import { colors, fontWeight, fontFamily, breakpoint, rgba, trans } from 'src/styledVars';

export const Arrow = styled.span<{ hide: boolean }>`
  font-size: 20px;
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -1px;
  transition: ${trans.normal};
`;

export const VisitButton = styled.a<{ underline: boolean }>`
  position: absolute;
  right: 20px;
  bottom: 20px;
  color: ${colors.orange};
  background-color: ${colors.white};
  line-height: 25px;
  font-size: 11px;
  font-weight: ${fontWeight.normal};
  letter-spacing: 3.74px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: ${fontFamily.default};
  padding: 10px 34px;
  z-index: 4;
  display: flex;
  align-items: center;
  transition: ${trans.normal};
  text-align: center;

  ${breakpoint.hover} {
    &:hover {
      box-shadow: 1px 1px 10px 0 ${rgba(colors.black, 0.3)};

      ${Arrow} {
        transform: translateX(2px);
      }
    }
  }
  ${props =>
    !props.underline &&
    css`${breakpoint.sm}{
    line-height 18px;
    max-width: 80%;
    width: 100%;
    right: 0;
    left: 0;
    margin: 0 auto;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
  }`}

  ${props =>
    props.underline &&
    css`
      text-decoration: underline;
      right: auto;
      left: 50%;
      padding: 0;
      background: none;
      line-height: 1;
      bottom: 46px;
      transform: translateX(-50%);

      ${Arrow} {
        display: none;
      }

      ${breakpoint.hover} {
        &:hover {
          box-shadow: none;
        }
      }
    `}
`;
