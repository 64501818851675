import styled from 'styled-components';
import { trans } from 'src/styledVars';

export const SubthemeTopics = styled.section<{ isTopicsActive?: boolean }>`
  left: 0;
  opacity: ${props => (props.isTopicsActive ? 1 : 0)};
  position: absolute;
  top: 0;
  transform: ${props => (props.isTopicsActive ? 'translateY(0%)' : 'translateY(100%)')};
  transition: transform ${trans.long}, opacity ${trans.long};
`;

export const SubthemeTopicsContent = styled.div`
  display: block;
  flex-direction: column;
  flex: 1 1 auto;
  height: var(--vh);
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const SubthemeTopicsContentImage = styled.div<{ bgImage: string }>`
  background-image: ${props => (props.bgImage ? `url(${props.bgImage})` : 'none')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  height: var(--vh);
  position: relative;
  width: 100vw;
`;
