import { Dispatch } from 'redux';
import { VisitButtonState } from './model';
import {
  SET_VISIT_BUTTON_CURRENT,
  SET_VISIT_BUTTON_DEFAULT,
  SetVisitButtonCurrent,
  SetVisitButtonDefault,
  RESET_VISIT_BUTTON,
  ResetVisitButton,
} from '../visitButton/actionType';
export const setVisitButtonCurrent = (
  state: VisitButtonState
): ((dispatch: Dispatch<SetVisitButtonCurrent>) => Promise<void>) => async (
  dispatch: Dispatch<SetVisitButtonCurrent>
) => {
  dispatch({
    type: SET_VISIT_BUTTON_CURRENT,
    payload: state,
  });
};

export const setVisitButtonDefault = (state: {
  [name: string]: VisitButtonState;
}): ((dispatch: Dispatch<SetVisitButtonDefault>) => Promise<void>) => async (
  dispatch: Dispatch<SetVisitButtonDefault>
) => {
  dispatch({
    type: SET_VISIT_BUTTON_DEFAULT,
    payload: state,
  });
};

export const resetVisitButton = (lang: string): ((dispatch: Dispatch<ResetVisitButton>) => Promise<void>) => async (
  dispatch: Dispatch<ResetVisitButton>
) => {
  dispatch({
    type: RESET_VISIT_BUTTON,
    payload: lang,
  });
};
