import {
  VisitButtonDispatchType,
  SET_VISIT_BUTTON_CURRENT,
  SET_VISIT_BUTTON_DEFAULT,
  RESET_VISIT_BUTTON,
} from './actionType';
import { VisitButtonState } from './model';

interface DefaultState {
  default: { [name: string]: VisitButtonState };
  current: VisitButtonState;
}

const defaultState: DefaultState = {
  default: {
    en: {
      label: '',
      link: '',
    },
    de: {
      label: '',
      link: '',
    },
    fr: {
      label: '',
      link: '',
    },
    it: {
      label: '',
      link: '',
    },
  },
  current: {
    label: '',
    link: '',
  },
};

const visitButtonReducer = (state: DefaultState = defaultState, action: VisitButtonDispatchType): DefaultState => {
  switch (action.type) {
    case SET_VISIT_BUTTON_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case SET_VISIT_BUTTON_DEFAULT:
      return {
        ...state,
        default: action.payload,
      };
    case RESET_VISIT_BUTTON:
      if (!state.default[action.payload]) return state;
      return {
        ...state,
        current: state.default[action.payload],
      };
    default:
      return state;
  }
};

export default visitButtonReducer;
