import { UserInfoDispatchTypes, CREATE_USER_ID_PROPERTIES, UPDATE_USER_EMAIL } from './actionType';

interface DefaultState {
  timestamp: number;
  uid: string;
  email: string;
}

const defaultState: DefaultState = {
  timestamp: 0,
  uid: '',
  email: '',
};

const userInfoReducer = (state: DefaultState = defaultState, action: UserInfoDispatchTypes): DefaultState => {
  switch (action.type) {
    case CREATE_USER_ID_PROPERTIES:
      return {
        email: '',
        timestamp: action.payload.timestamp,
        uid: action.payload.uid,
      };
    case UPDATE_USER_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    default:
      return state;
  }
};

export default userInfoReducer;
