import { RootStore } from 'src/stores/rootStore';
import { UserAnswer, UserData } from 'src/stores/userAnswer/model';
import { VisitButtonState } from 'src/stores/visitButton/model';

export const unsendUserAnswersAndUserInfoSelector = (state: RootStore): UserData => {
  const userAnswers = state.userAnswer.unsendUserAnswers;
  const userUid = state.userInfo.uid;
  const userTimestamp = state.userInfo.timestamp;
  return { userAnswers, userUid, userTimestamp };
};

export const userAnswersSelector = (state: RootStore): UserAnswer[] => {
  return state.userAnswer.unsendUserAnswers;
};

export const userUidSelector = (state: RootStore): string => {
  return state.userInfo.uid;
};

export const userTimestampSelector = (state: RootStore): number => {
  return state.userInfo.timestamp;
};

export const visitButtonSelector = (state: RootStore): VisitButtonState => {
  return state.visitButton.current;
};
