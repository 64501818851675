import React from 'react';
import { useSelector } from 'react-redux';
import { visitButtonSelector } from 'src/helper/selector';
import * as Styled from './styled';
interface DataProps {
  underline: boolean;
}

type Props = DataProps;

const VisitButton: React.FC<Props> = ({ underline }) => {
  const { link, label } = useSelector(visitButtonSelector);
  if (!link || !label) return null;
  return (
    <Styled.VisitButton href={link} target='_blank' underline={underline}>
      {label}
      <Styled.Arrow hide={underline}>⟶</Styled.Arrow>
    </Styled.VisitButton>
  );
};

export default VisitButton;
