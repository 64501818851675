import hexRgb from 'hex-rgb';

export const fontFamily = {
  default: `'MaisonNeue', Arial, -apple-system, BlinkMacSystemFont, sans-serif`,
  secondary: `'FreightText Pro', 'Trebuchet MS', Helvetica, -apple-system, BlinkMacSystemFont, sans-serif`,
};

export const colors = {
  white: '#fff',
  black: '#3f3f3f',
  grey: '#ddd',
  dark: '#1d1d1b',
  darkGrey: '#a7b2c1',
  orange: '#ff9c09',
  yellow: '#ffb64a',
  gold: '#ffb547',
  blue: '#254067',
  darkBlue: '#0c284c',
  orange1: '#ff6c0e',
  sand: '#ffc545',
  darkOrange: '#ff6c0e',
  orange2: '#ff7917',
  grey2: '#d1d1d1',
  red: '#d30000',
};

export const trans = {
  long: '0.8s ease',
  normal: '0.3s ease',
  normalEaseInOut: '0.3s ease-in-out',
  fast: '0.1s ease',
};

export const duration = {
  normal: '0.3s',
};

export const bp = {
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 375,
  xxs: 320,
};

export const breakpoint = {
  xxs: `@media only screen and (max-width: ${bp.xxs}px)`,
  xs: `@media only screen and (max-width: ${bp.xs}px)`,
  xsExtended: `@media only screen and (max-width: ${bp.sm}px) and (min-width: ${bp.xs + 1}px)`,
  sm: `@media only screen and (max-width: ${bp.sm}px)`,
  md: `@media only screen and (max-width: ${bp.md}px)`,
  lg: `@media only screen and (max-width: ${bp.lg}px)`,
  xl: `@media only screen and (max-width: ${bp.xl}px)`,
  mdExtended: `@media only screen and (max-width: 823px)`,
  tablet: `@media only screen and (min-width: ${bp.md + 1}px) and (max-width: ${bp.lg}px)`,
  mobile: `@media screen and (min-width: ${bp.xxs}px) and (max-width: 823px) and (orientation: landscape)`,
  tabletExtendedWide: `@media screen and (min-width: 736px) and (max-width: 823px) and (orientation: landscape)`,
  hover: `@media not all and (pointer: coarse), (-ms-high-contrast:active), (-ms-high-contrast:none)`,
  touch: `@media pointer: coarse`,
  grid: `@supports (display: grid)`,
};

export const matchMedia = {
  desktop: `(min-width: ${bp.lg}px)`,
  tablet: `(max-width: ${bp.lg - 1}px)`,
  mobile: `(min-width: ${bp.md - 1}px)`,
};

export const fontWeight = {
  light: 300,
  book: 400,
  normal: 500,
  demi: 600,
  bold: 700,
  black: 800,
};

// eslint-disable-next-line
export const rgba = (hex: string, opacity: number) => {
  const rgb = hexRgb(hex);
  return `rgba(${rgb.red}, ${rgb.green}, ${rgb.blue}, ${opacity})`;
};
