import { Question } from 'src/stores/question/model';
import { RootStore } from 'src/stores/rootStore';
import { UserAnswer } from 'src/stores/userAnswer/model';

export const userAnswersSelector = (state: RootStore): UserAnswer[] => {
  return state.userAnswer.userAnswers;
};

export const questionsSelector = (state: RootStore): Question[] => {
  return state.question.questions;
};
