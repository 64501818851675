import React from 'react';
import { createPortal } from 'react-dom';
import * as Styled from './styled';

interface OwnProps {
  modalOpen: boolean;
  onClose: () => void;
  icon?: string;
  alt?: string;
  isRegular?: boolean;
}

type Props = OwnProps;

const Modal: React.FC<Props> = ({ modalOpen, onClose, icon, alt, isRegular, children }) => {
  if (!modalOpen) return null;

  const modalElement = document.getElementById('modal');

  return modalElement
    ? createPortal(
        <>
          <Styled.ModalBackdrop isMobileScroll={modalOpen} />
          <Styled.ModalContainer isOpen={modalOpen} isMobileScroll={modalOpen}>
            <Styled.Modal>
              <Styled.ModalIco>
                <Styled.ModalIcoSvg src={icon} alt={alt || ''} />
              </Styled.ModalIco>
              <Styled.ModalHolder isRegular={isRegular || false}>
                {children}
                <Styled.ModalClose onClick={onClose} />
              </Styled.ModalHolder>
            </Styled.Modal>
          </Styled.ModalContainer>
        </>,
        modalElement
      )
    : null;
};
export default Modal;
