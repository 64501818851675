import { VisitButtonState } from './model';

export const SET_VISIT_BUTTON_CURRENT = 'SET_VISIT_BUTTON_CURRENT';
export const SET_VISIT_BUTTON_DEFAULT = 'SET_VISIT_BUTTON_DEFAULT';
export const RESET_VISIT_BUTTON = 'RESET_VISIT_BUTTON';

export interface SetVisitButtonCurrent {
  type: typeof SET_VISIT_BUTTON_CURRENT;
  payload: VisitButtonState;
}

export interface SetVisitButtonDefault {
  type: typeof SET_VISIT_BUTTON_DEFAULT;
  payload: { [name: string]: VisitButtonState };
}

export interface ResetVisitButton {
  type: typeof RESET_VISIT_BUTTON;
  payload: string;
}

export type VisitButtonDispatchType = SetVisitButtonCurrent | SetVisitButtonDefault | ResetVisitButton;
