import { Category } from '../stores/category/model';
import { Question } from '../stores/question/model';
import { RootStore } from '../stores/rootStore';

export const lastFetchDateTimeSelector = (state: RootStore): number => {
  const lastFetchDateTime = state.userAnswer.lastFetchDateTime;
  return lastFetchDateTime ? lastFetchDateTime : 0;
};

export const categoriesInAllLngsSelector = (state: RootStore): Category[] => {
  return state.category.categoriesInAllLngs;
};

export const questionsInAllLngsSelector = (state: RootStore): Question[] => {
  return state.question.questionsInAllLngs;
};

export const userTimeStampSelector = (state: RootStore): number => {
  return state.userInfo.timestamp;
};
