import { QuestionDispatchTypes, QUESTION_FAIL, FETCH_QUESTIONS, SET_QUESTIONS_IN_LANGUAGE } from './actionType';
import { Question } from './model';

interface DefaultState {
  loading: boolean;
  questions: Question[];
  questionsInAllLngs: Question[];
}

const defaultState: DefaultState = {
  loading: false,
  questions: [],
  questionsInAllLngs: [],
};

const questionReducer = (state: DefaultState = defaultState, action: QuestionDispatchTypes): DefaultState => {
  switch (action.type) {
    case QUESTION_FAIL:
      return {
        ...state,
        loading: false,
      };
    case FETCH_QUESTIONS:
      return {
        loading: false,
        questions: action.payload.questionsInStartingLanguage,
        questionsInAllLngs: action.payload.questionsInAllLngs,
      };
    case SET_QUESTIONS_IN_LANGUAGE:
      return {
        ...state,
        questions: action.payload,
      };
    default:
      return state;
  }
};

export default questionReducer;
