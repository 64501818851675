/*eslint-disable @typescript-eslint/no-explicit-any*/
import React, { useMemo } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Languages } from '../../Header';
import { colors, fontWeight } from 'src/styledVars';
import * as Styled from './styled';

interface OwnProps {
  onChange: (selectedOption: any) => void;
}

interface DataProps {
  options: any;
}

type Props = OwnProps & DataProps;

const CustomSelect: React.FC<Props> = ({ onChange, options }) => {
  const { i18n } = useTranslation();

  const defaultSelectValue = useMemo(() => {
    return options.find((option: Languages) => option.value === i18n.languages[0]);
  }, [options, i18n.languages]);

  const customStyles = useMemo(() => {
    return {
      option: (styles: any, state: any) => ({
        ...styles,
        backgroundColor: colors.white,
        borderBottom: state.isFocused ? `1px solid ${colors.black}` : `1px solid transparent`,
        color: colors.black,
        cursor: 'pointer',
        fontSize: 11,
        fontWeight: fontWeight.normal,
        letterSpacing: 3.74,
        lineHeight: '19px',
        margin: '0 auto',
        padding: 0,
        width: 20,
        textAlign: 'center',
        textTransform: 'uppercase',
      }),
      container: (styles: any) => ({
        ...styles,
        transform: 'translateY(1px)',
      }),
      control: (styles: any, state: any) => ({
        ...styles,
        backgroundColor: 'transparent',
        border: state.isFocused ? 0 : 0,
        borderBottom: `1px solid ${colors.black}`,
        borderRadius: 0,
        cursor: 'pointer',
        minHeight: 15,
        minWidth: 33,
        paddingBottom: 3,
        boxShadow: 'none',
        '&:hover': {
          borderColor: 'transparent',
        },
      }),
      indicatorSeparator: () => ({ display: 'none' }),
      indicatorsContainer: (styles: any) => ({
        ...styles,
        color: colors.black,
      }),
      dropdownIndicator: (styles: any) => ({
        ...styles,
        color: colors.black,
        padding: 0,
        '& svg': {
          height: 12,
          width: 12,
        },
      }),
      menu: (styles: any) => ({
        ...styles,
        borderColor: colors.white,
        borderRadius: 0,
        margin: 0,
        padding: 0,
        top: 21,
      }),
      singleValue: (styles: any) => ({
        ...styles,
        color: colors.black,
        cursor: 'pointer',
        fontSize: 11,
        fontWeight: fontWeight.normal,
        letterSpacing: 3.74,
        lineHeight: '19px',
        margin: 0,
        overflow: 'visible',
        padding: 0,
        textOverflow: 'initial',
        textTransform: 'uppercase',
        whiteSpace: 'normal',
      }),
      valueContainer: (styles: any) => ({
        ...styles,
        padding: 0,
      }),
    };
  }, []);

  return (
    <Styled.CustomSelect>
      <Select
        styles={customStyles}
        isMulti={false}
        options={options}
        onChange={onChange}
        hideSelectedOptions={true}
        isSearchable={false}
        defaultValue={defaultSelectValue}
      />
    </Styled.CustomSelect>
  );
};

export default CustomSelect;
