import { UserInfoDispatchTypes, CREATE_USER_ID_PROPERTIES, UPDATE_USER_EMAIL } from './actionType';
import { Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';

export const createUserIdProperties = (): ((dispatch: Dispatch<UserInfoDispatchTypes>) => void) => (
  dispatch: Dispatch<UserInfoDispatchTypes>
) => {
  const userIdProperties = {
    timestamp: Date.now(),
    uid: uuidv4(),
  };
  dispatch({
    type: CREATE_USER_ID_PROPERTIES,
    payload: userIdProperties,
  });
};

// eslint-disable-next-line
export const udateUserEmail = (email: string) => (dispatch: Dispatch<UserInfoDispatchTypes>) => {
  dispatch({
    type: UPDATE_USER_EMAIL,
    payload: email,
  });
};
