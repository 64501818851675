/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RenderRoutes } from 'src/helper/RenderRoutes';
import Header from 'src/components/Header';
import { useAppRoutes, STATIC_ROUTES } from 'src/features/routes';
import { refreshLastFetchDateTime, sendAgainUnsendUserData } from 'src/stores/userAnswer/action';
import { fetchCategories, setCategoriesInLanguage } from 'src/stores/category/action';
import { fetchQuestions, setQuestionsInLanguage } from 'src/stores/question/action';
import { createUserIdProperties } from 'src/stores/userInfo/action';
import {
  lastFetchDateTimeSelector,
  categoriesInAllLngsSelector,
  questionsInAllLngsSelector,
  userTimeStampSelector,
} from './selectors';
import { userAnswersSelector, userUidSelector, userTimestampSelector } from 'src/helper/selector';
import 'src/scss/fonts.scss';
import GlobalStyles from 'src/styles';
import VisitButton from 'src/components/VisitButton';
import { resetVisitButton } from 'src/stores/visitButton/actions';
import { useLocation } from 'react-router';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { i18n } = useTranslation();
  const fetchedRoutes = useAppRoutes();
  const routes = useMemo(() => fetchedRoutes, [fetchedRoutes]);
  const lastFetchTime = useSelector(lastFetchDateTimeSelector);
  const categoriesInAllLngs = useSelector(categoriesInAllLngsSelector);
  const questionsInAllLngs = useSelector(questionsInAllLngsSelector);
  const firstRenderingTime = useSelector(userTimeStampSelector);
  const userUnsendAnswers = useSelector(userAnswersSelector);
  const userUid = useSelector(userUidSelector);
  const userTimestamp = useSelector(userTimestampSelector);
  const [size, setSize] = useState(0);

  // SET USER ID INFORMATION ON FIRST TIME RENDER APP
  useEffect(() => {
    if (firstRenderingTime === 0) {
      dispatch(createUserIdProperties());
    }
  }, [dispatch, firstRenderingTime]);

  // FETCHING DATA FROM SERVER AND SET CURRENT LANGUAGE
  useEffect(() => {
    if (
      Date.now() - lastFetchTime >
      parseInt(
        process.env.REACT_APP_TIME_NEEDED_FOR_THE_NEXT_FETCH_IN_MILLISECONDS
          ? process.env.REACT_APP_TIME_NEEDED_FOR_THE_NEXT_FETCH_IN_MILLISECONDS
          : '0'
      )
    ) {
      dispatch(refreshLastFetchDateTime());
      dispatch(fetchCategories(i18n.languages[0]));
      dispatch(fetchQuestions(i18n.languages[0]));
    }
  }, [dispatch, lastFetchTime, i18n.languages]);

  // CHANGING CONTENT BY CHOOSEN LANGUAGE
  useEffect(() => {
    if (categoriesInAllLngs.length > 0) dispatch(setCategoriesInLanguage(categoriesInAllLngs, i18n.languages[0]));
  }, [dispatch, categoriesInAllLngs, i18n.languages]);

  useEffect(() => {
    if (questionsInAllLngs.length > 0) dispatch(setQuestionsInLanguage(questionsInAllLngs, i18n.languages[0]));
  }, [dispatch, questionsInAllLngs, i18n.languages]);

  // RESEND USER ANSWERS IF THERE IS SOME
  useEffect(() => {
    if (userUnsendAnswers && userUnsendAnswers.length > 0)
      dispatch(sendAgainUnsendUserData({ userAnswers: userUnsendAnswers, userUid, userTimestamp }));
  }, [dispatch, userUnsendAnswers, userUid, userTimestamp]);

  // FIND INNER HEIGHT OF DEVICE AND STORE VALUE IN STATE AND PASS TO CSS VARIBALE
  useEffect(() => {
    function updateSize() {
      setSize(window.innerHeight);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    const staticPaths = STATIC_ROUTES.map(e => e.path);
    if (staticPaths.includes(location.pathname)) {
      dispatch(resetVisitButton(i18n.language));
    }
  }, [dispatch, location, i18n.language]);

  return (
    <div className='App'>
      <GlobalStyles innerHeight={size} />
      <Header />
      <RenderRoutes routes={routes} />
      {/* VISIT BUTTON */}
      <VisitButton underline={false} />
      <div id='modal' />
    </div>
  );
};

export default App;
