import { Category } from './model';

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const SET_CATEGORIES_IN_LANGUAGE = 'SET_CATEGORIES_IN_LANGUAGE';
export const CATEGORIES_FAIL = 'CATEGORIES_FAIL';

interface PayloadForFetch {
  categoriesInAllLngs: Category[];
  categoriesInStartingLanguage: Category[];
}

export interface FetchCategories {
  type: typeof FETCH_CATEGORIES;
  payload: PayloadForFetch;
}

export interface SetCategoriesInLanguage {
  type: typeof SET_CATEGORIES_IN_LANGUAGE;
  payload: Category[];
}

export interface CategoryFail {
  type: typeof CATEGORIES_FAIL;
}

export type CategoryDispatchTypes = FetchCategories | SetCategoriesInLanguage | CategoryFail;
