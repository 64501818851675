import styled, { css } from 'styled-components';
import { breakpoint, colors, fontFamily, fontWeight, trans } from 'src/styledVars';
import { Fullviewport } from '../styled';

export const SubthemeIntro = styled(Fullviewport)<{ isTopicsActive?: boolean }>`
  left: 0;
  opacity: ${props => (props.isTopicsActive ? 0 : 1)};
  position: absolute;
  top: 0;
  transform: ${props => (props.isTopicsActive ? 'translateY(40%)' : 'translateY(0%)')};
  transition: transform ${trans.long}, opacity ${trans.long};
`;

export const SubthemeIntroContent = styled.section`
  position: relative;
`;

export const Banner = styled.section`
  width: 100vw;
  height: var(--vh);
  display: flex;
`;

export const BannerHolder = styled.div<{ theme?: boolean }>`
  max-width: ${props => (props.theme ? 'none' : '900px')};
  width: 900px;
  margin: 0 auto;
  padding-top: 10vh;
  text-align: center;
  position: relative;
  z-index: 2;
  display: ${props => (props.theme ? 'flex' : 'block')};

  ${props =>
    props.theme &&
    css`
      align-items: center;
      flex-direction: column;
      justify-content: center;
    `}

  ${breakpoint.tablet} {
    padding-top: 100px;
  }

  ${breakpoint.md} {
    padding-top: 12vh;
  }

  ${breakpoint.mobile} {
    padding-top: 6vh;
  }
`;

export const BannerThemeIntroHolder = styled.div`
  ${breakpoint.mobile} {
    margin-bottom: -21px;
  }
`;

export const BannerThemeTitle = styled.h2`
  color: ${colors.orange};
  display: inline-block;
  font-family: ${fontFamily.default};
  font-size: 65px;
  font-weight: ${fontWeight.black};
  letter-spacing: 16px;
  margin-bottom: 3vh;
  padding: 0 20px;
  position: relative;
  text-indent: 16px;
  text-transform: uppercase;

  &::before,
  &::after {
    background-color: currentColor;
    border-radius: 50%;
    content: '';
    height: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  ${breakpoint.tablet} {
    font-size: 48px;
    letter-spacing: 12px;
    text-indent: 12px;
  }

  ${breakpoint.md} {
    font-size: calc(9px + 4.6vw);
    letter-spacing: 5px;
    margin-bottom: 2vh;
    padding: 0 15px;
    text-indent: 5px;

    &::before,
    &::after {
      height: 2vw;
      max-height: 8px;
      max-width: 8px;
      width: 2vw;
    }
  }

  ${breakpoint.mobile} {
    font-size: 26px;
    letter-spacing: 5px;
    margin-bottom: 2vh;
    padding: 0 15px;
    text-indent: 5px;

    &::before,
    &::after {
      height: 2vw;
      max-height: 8px;
      max-width: 8px;
      width: 2vw;
    }
  }

  ${breakpoint.xs} {
    font-size: 4.6vw;
  }
`;

export const BannerThemeIntro = styled.p`
  font-size: 17px;
  font-weight: ${fontWeight.book};
  line-height: 20px;
  margin: 0 auto 4vh;
  max-width: 430px;

  ${breakpoint.md} {
    margin-bottom: 3vh;
    max-width: 228px;
  }

  ${breakpoint.mobile} {
    margin-bottom: 3vh;
    max-width: 228px;
  }
`;

export const CtaButtonLine = styled.span`
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 100% 3px;
  padding-left: 5px;
  text-decoration: none;
  transition: background-size ${trans.normalEaseInOut};
  width: 100%;
`;

export const CtaButton = styled.button<{ light?: boolean; ctaDone?: boolean; fadeIn?: boolean; fadeOut?: boolean }>`
  color: ${colors.black};
  display: inline-block;
  font-family: ${fontFamily.default};
  font-size: 17px;
  font-weight: ${fontWeight.normal};
  letter-spacing: 5.24px;
  margin-left: -2.5px;
  max-width: 400px;
  opacity: ${props => (props.ctaDone ? 0 : 1)};
  pointer-events: ${props => (props.ctaDone ? 'none' : 'all')};
  position: relative;
  text-indent: 5.24px;
  text-transform: uppercase;
  transition: ${trans.normal};

  &::after {
    background-color: ${colors.black};
    bottom: -4px;
    content: '';
    height: 3px;
    left: calc(50% + 2.5px);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transform: translateX(-50%);
    transition: ${trans.normal};
    width: calc(100% - 5px);
  }

  ${props =>
    props.fadeIn &&
    css`
      opacity: 1;
      pointer-events: all;
    `}

  ${props =>
    props.fadeOut &&
    css`
      opacity: 0;
      pointer-events: none;
    `}

  ${breakpoint.hover} {
    &:hover {
      color: ${props => (props.light ? colors.white : colors.grey)};

      ${CtaButtonLine} {
        background-size: 0 3px;
        display: inline;
      }

      &::after {
        background-color: ${props => (props.light ? colors.grey : colors.white)};
        height: calc(100% + 11px);
        width: calc(100% + 12px);
        z-index: -1;
        opacity: 1;
      }
    }
  }
`;
