import styled, { css } from 'styled-components';
import { breakpoint, colors, trans, rgba } from 'src/styledVars';
import {
  ModalIntroHolder,
  ModalRating,
  ModalTitle,
  ModalShare,
  ModalAnswer,
  CtaHolder,
} from 'src/features/ScreenSubtheme/QuestionModal/styled';
import { SubthemeTopics } from 'src/features/ScreenSubtheme/Topics/styled';

export const ModalBackdrop = styled.div<{ isMobileScroll?: boolean }>`
  background-color: ${rgba(colors.white, 0.5)};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;

  @supports (backdrop-filter: blur(6px)) {
    animation: backdrop-blur-in ${trans.long} forwards;
    background-color: transparent;
  }
  ${breakpoint.mobile} {
    height: ${props => (props.isMobileScroll ? '525px' : '100%')};
  }
`;

export const Modal = styled.div`
  height: 600px;
  position: relative;
  width: 740px;
  display: flex;
  z-index: 6;

  ${breakpoint.lg} {
    max-width: calc(100% - 50px);
  }

  ${breakpoint.md} {
    height: 67%;
    margin-bottom: -5vh;
    max-height: 520px;
    min-height: 520px;
  }

  ${breakpoint.mobile} {
    height: 200px;
    min-height: auto;
  }

  ${breakpoint.xxs} {
    max-height: 485px;
    min-height: 485px;
  }
`;

export const ModalIcoSvg = styled.img`
  display: inline-block;
  flex: none;
  height: 55px;
  object-fit: contain;
  width: 55px;

  ${breakpoint.md} {
    height: 45px;
    width: 45px;
  }
`;

export const ModalIco = styled.div`
  align-items: center;
  background-image: linear-gradient(to bottom, ${colors.darkOrange}, ${colors.sand});
  border-radius: 50%;
  display: flex;
  height: 94px;
  justify-content: center;
  left: 50%;
  position: absolute;
  top: -47px;
  transform: translateX(-50%);
  width: 94px;
  z-index: 4;

  ${breakpoint.md} {
    height: 78px;
    top: -55px;
    width: 78px;
  }
`;

export const ModalHolder = styled.div<{ isRegular?: boolean }>`
  background-color: ${colors.white};
  border-radius: 84px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
  padding: 70px 0 30px;
  position: relative;
  text-align: center;

  &::before,
  &::after {
    content: '';
    height: 100%;
    opacity: ${props => (props.isRegular ? 0 : 1)};
    position: absolute;
    top: 0;
    width: 20%;
    z-index: 2;
  }

  &::before {
    background-image: linear-gradient(to right, ${colors.white} 10%, ${rgba(colors.white, 0.1)});
    left: 0;
  }

  &::after {
    background-image: linear-gradient(to left, ${colors.white} 10%, ${rgba(colors.white, 0.1)});
    right: 0;
  }

  ${breakpoint.md} {
    border-radius: 40px;

    &::before,
    &::after {
      width: 13%;
    }
  }
`;

export const ModalClose = styled.button`
  height: 30px;
  position: absolute;
  right: 8%;
  top: 45px;
  width: 30px;
  z-index: 3;

  &::before,
  &::after {
    background-color: ${colors.grey};
    content: '';
    height: 30px;
    left: 50%;
    position: absolute;
    top: 0;
    width: 2px;
  }

  &::before {
    transform: translateX(-50%) rotate(45deg);
  }

  &::after {
    transform: translateX(-50%) rotate(-45deg);
  }

  ${breakpoint.md} {
    height: 19px;
    top: 40px;
    width: 19px;

    &::before,
    &::after {
      height: 19px;
    }
  }
`;

export const ModalContainer = styled.div<{ isOpen: boolean; isMobileScroll?: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: ${props => (props.isOpen ? 1 : 0)};
  position: absolute;
  top: 0;
  transition: opacity ${trans.long};
  width: 100%;
  z-index: ${props => (props.isOpen ? 10 : -1)};

  ${props =>
    props.isMobileScroll &&
    css`
      ${breakpoint.mobile} {
        align-items: flex-start;
        height: 525px;

        ${Modal} {
          height: 440px;
          margin-bottom: 0;
          margin-top: 52px;
          min-height: auto;
          margin-left: auto;
          margin-right: auto;
        }

        ${ModalIco} {
          height: 78px;
          top: -55px;
          width: 78px;
        }

        ${ModalIntroHolder} {
          padding: 0 12vw;
          top: 3px;
        }

        ${ModalTitle} {
          font-size: 16px;
          letter-spacing: 4px;
          text-indent: 4px;
          top: 1px;
          margin-top: -40px;
        }

        ${ModalShare} {
          right: -60px;
        }

        ${ModalAnswer} {
          align-items: flex-end;
        }

        ${ModalRating} {
          font-size: 28px;
        }

        ${CtaHolder} {
          bottom: 3px;
        }

        & + ${SubthemeTopics} {
          height: 525px;
        }
      }
    }
  `};
`;
