import { combineReducers } from 'redux';
import questionReducer from './question/reducer';
import categoryReducer from './category/reducer';
import userAnswerReducer from './userAnswer/reducer';
import userInfoReducer from './userInfo/reducer';
import visitButtonReducer from './visitButton/reducer';

const RootReducer = combineReducers({
  question: questionReducer,
  category: categoryReducer,
  userAnswer: userAnswerReducer,
  userInfo: userInfoReducer,
  visitButton: visitButtonReducer,
});

export default RootReducer;
