import { QuestionDispatchTypes, FETCH_QUESTIONS, QUESTION_FAIL, SET_QUESTIONS_IN_LANGUAGE } from './actionType';
import { Dispatch } from 'redux';
import { MOCKED_QUESTIONS } from './mockData';
import { Question } from './model';
import { questionUrls } from '../../urls';
import axios from 'axios';

function getMockData() {
  const response = {
    data: MOCKED_QUESTIONS,
  };
  return response;
}

export const fetchQuestions = (
  startingLanguage: string
): ((dispatch: Dispatch<QuestionDispatchTypes>) => Promise<void>) => async (
  dispatch: Dispatch<QuestionDispatchTypes>
) => {
  try {
    let res;
    if (process.env.REACT_APP_FETCH_MOCK_CATEGORIES_AND_QUESTIONS === 'true') res = await getMockData();
    else res = await axios.get(questionUrls.getAllQuestionsInAllLngs);

    dispatch({
      type: FETCH_QUESTIONS,
      payload: {
        questionsInAllLngs: res.data,
        questionsInStartingLanguage: res.data.filter((question: Question) => question.language === startingLanguage),
      },
    });
  } catch (e) {
    dispatch({
      type: QUESTION_FAIL,
    });
  }
};

export const setQuestionsInLanguage = (
  allQuestions: Question[],
  choosenLanguage: string
): ((dispatch: Dispatch<QuestionDispatchTypes>) => void) => (dispatch: Dispatch<QuestionDispatchTypes>) => {
  const updatedQuestions = allQuestions.filter((question: Question) => question.language === choosenLanguage);

  dispatch({
    type: SET_QUESTIONS_IN_LANGUAGE,
    payload: updatedQuestions,
  });
};
