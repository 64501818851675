/*eslint-disable @typescript-eslint/no-explicit-any*/
import React, { useCallback, useEffect, useState, MouseEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomSelect from './CustomSelect';
import Modal from 'src/components/Modal';
import DownloadForm from './DownloadForm';
import { numberOfQuestionsSelector, numberOfAnsweredQuestionsSelector } from './selectors';
import { PATHS } from 'src/features/routes';
import * as Styled from './styled';

export type Languages = {
  value: string;
  label: string;
};

const languagesList = [
  { value: 'en', label: 'EN' },
  { value: 'de', label: 'DE' },
  { value: 'fr', label: 'FR' },
  { value: 'it', label: 'IT' },
];

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [isLanding, setIsLanding] = useState(false);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const numberOfQuestions = useSelector(numberOfQuestionsSelector);
  const numberOfAnsweredQuestions = useSelector(numberOfAnsweredQuestionsSelector);

  const handleChange = (selectedOption: any) => {
    i18n.changeLanguage(selectedOption.value);
    return selectedOption;
  };

  const handleMobileClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      i18n.changeLanguage(e.currentTarget.lang);
    },
    [i18n]
  );

  const handleDownloadClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleHamburgerClick = useCallback(() => {
    setIsHamburgerOpen(toggleClick => !toggleClick);
  }, []);

  useEffect(() => {
    setIsLanding(location.pathname === PATHS.landingPage);
    setIsHamburgerOpen(false);
  }, [location]);

  const LanguageButtonList = useMemo(() => {
    return languagesList.map((language: Languages, index: number) => {
      return (
        <Styled.HeaderLangItem
          key={index + 1}
          onClick={handleMobileClick}
          isActive={language.value === i18n.languages[0]}
          lang={language.value}
        >
          {language.label}
        </Styled.HeaderLangItem>
      );
    });
  }, [handleMobileClick, i18n.languages]);

  return (
    <>
      <Styled.MainHeader>
        <Styled.Wrap>
          <Styled.HeaderHolder>
            <Styled.TouchNav isLanding={isLanding} onClick={handleHamburgerClick}>
              <span></span>
            </Styled.TouchNav>
            <Styled.HeaderHomeHolder isLanding={isLanding}>
              <Link to='/'>
                <Styled.Logo logoimage='/images/Syz-logo-RGB-vignette.png' isLanding={isLanding} />
              </Link>
              <Styled.HeaderHome to={PATHS.landingPage}>{t('shared.title')}</Styled.HeaderHome>
            </Styled.HeaderHomeHolder>
            <Styled.HeaderDownload>
              <Styled.HeaderMobNav isMenuOpen={isHamburgerOpen} isLanding={isLanding}>
                <Styled.HeaderMobNavClose onClick={handleHamburgerClick} />
                <Styled.HeaderMobLang>
                  <Link to='/'>
                    <Styled.LogoMenu logoimage='/images/Syz-logo-RGB-vignette.png' />
                  </Link>
                  <Styled.HeaderLangTitle>{t('header.langTitle')}</Styled.HeaderLangTitle>
                  <Styled.HeaderLang>{LanguageButtonList}</Styled.HeaderLang>
                </Styled.HeaderMobLang>
                <Styled.HeaderDownloadText isLanding={isLanding}>
                  <Styled.HeaderDownloadTextLink onClick={handleDownloadClick}>
                    {t('header.downloadLink')}
                  </Styled.HeaderDownloadTextLink>
                  {t('header.downloadRapport')}
                </Styled.HeaderDownloadText>
                <CustomSelect onChange={handleChange} options={languagesList} />
              </Styled.HeaderMobNav>
              <Styled.HeaderDownloadCounter isLanding={isLanding}>
                <Styled.HeaderDownloadCurrent>{numberOfAnsweredQuestions}</Styled.HeaderDownloadCurrent>
                <Styled.HeaderDownloadSumm>{numberOfQuestions}</Styled.HeaderDownloadSumm>
              </Styled.HeaderDownloadCounter>
            </Styled.HeaderDownload>
          </Styled.HeaderHolder>
          <Styled.HeaderBack isLanding={isLanding} onClick={handleBackClick}>
            {t('structuralElements.backBtn')}
          </Styled.HeaderBack>
        </Styled.Wrap>
      </Styled.MainHeader>
      <Modal
        modalOpen={modalOpen}
        onClose={closeModal}
        icon={'/images/icons/download.svg'}
        alt={t('header.downloadLink') + ' ' + t('header.downloadRapport')}
        isRegular={true}
      >
        <DownloadForm />
      </Modal>
    </>
  );
};

export default Header;
